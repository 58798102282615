.row-header {
  margin: 0px auto;
  padding: 0px auto;
}

.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.footer {
  background-color: #d1c4e9;
  margin: 0px auto;
  padding: 20px 0px 20px 0px;
}
.jumbotron {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 70px 30px 70px 30px;
  margin: 0px auto;
  background: #9575cd;
  color: floralwhite;
}

address {
  font-size: 80%;
  margin: 0px;
  color: #0f0f0f;
}

.navbar-dark {
  background-color: #512da8;
}
.btn-social-icon {
  margin: 1px;
}
.btn-email {
  color: #fff;
  background-color: #39dd4f;
  border-color: rgba(0, 0, 0, 0.2);
}
body {
  background-color: #e9ecef;
}

.page-enter{
  opacity: 0.01;
  transform: translateX(-100%);
}
.page-enter-active{
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}
.page-exit{
  opacity: 1;
  transform: translateX(0%);
}
.page-exit-active{
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}